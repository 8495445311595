import request from "@/core/services/axios";

export function exammarkBindQuestionList(query) {
  return request({
    url: "/exam/exammark/bindquestion/list",
    method: "get",
    params: query,
  });
}

export function exammarkRegion(query) {
  return request({
    url: "/exam/exammark/region",
    method: "get",
    params: query,
  });
}

// 设置绑定批阅-获取试题坐标
export function questionCoordinate(query) {
  return request({
    url: "/exam/exammark/question/coordinate",
    method: "get",
    params: query,
  });
}

// 设置绑定批阅-获取试题区域 图片
export function questionArea(query) {
  return request({
    url: "/exam/exammark/question/area",
    method: "get",
    params: query,
  });
}

// 设置绑定批阅-获取绑定进度
export function progressbar(query) {
  return request({
    url: "/exam/exammark/bind/progressbar",
    method: "get",
    params: query,
  });
}

// 保存绑定批阅
export function exammarkSaveBindQuestion(query) {
  return request({
    url: "/exam/exammark/save/bindquestion",
    method: "put",
    data: query,
  });
}

// 是否允许 绑定 参数和 绑定那个一样。
export function passBindquestion(query) {
  return request({
    url: "/exam/exammark/pass/bindquestion",
    method: "put",
    data: query,
  });
}

export function exammarkBindquestionDel(query) {
  return request({
    url: "/exam/exammark/bindquestion/del",
    method: "delete",
    params: query,
  });
}
