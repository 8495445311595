<template>
  <div class="ExamBindQuestion">
    <!-- <ExamDetailsHeader
      :url="'ExamCorrectTask'"
      :query="$route.query"
      :name="name + ' / ' + subjectName + ' / 阅卷分配 / 设置绑定批阅'"
    >
      <slot> </slot>
    </ExamDetailsHeader> -->
    <ExamDetailsHeader
      router-url="unionExamCorrectTask"
      :query="$route.query"
    ></ExamDetailsHeader>
    <div class="question-list-box">
      <el-button type="primary" @click="addBind()">
        <img src="@/assets/uExam/icon_add_w.png" alt="" />
        <img class="on" src="@/assets/uExam/icon_add_w.png" alt="" />
        添加绑定
      </el-button>
      <el-table :data="exammarkQuestionList" border style="width: 100%">
        <el-table-column
          prop="bindQuestionNum"
          label="绑定题号"
          width="220"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="questionNum" label="阅卷区域" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="exammarkRegion(scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
        <!-- <el-table-column prop="questionNum" label="调整阅卷区域" align="center">
          <div slot-scope="scope" class="btn-list">
            <template v-if="scope.row.answerArea.length == 1">
              <el-button
                type="text"
                @click="
                  editArea(scope.row, 0, scope.row.answerArea[0].priority)
                "
              >
                调整区域
              </el-button>
            </template>
            <template v-if="scope.row.answerArea.length > 1">
              <el-button
                v-for="(v, i) in scope.row.answerArea"
                :key="i"
                type="text"
                @click="editArea(scope.row, i, v.priority)"
              >
                调整第{{ i + 1 }}区域
                <i
                  class="el-icon--right el-icon-delete"
                  @click.stop="delArea(scope.row, i)"
                ></i>
              </el-button>
            </template>
          </div>
        </el-table-column> -->
        <!-- <el-table-column
          prop="questionNum"
          label="添加区域"
          width="220"
          align="center"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="addArea(scope.row, 1)">
              正面
            </el-button>
            <el-button type="text" @click="addArea(scope.row, 2)">
              反面
            </el-button>
          </template>
        </el-table-column> -->
        <el-table-column prop="questionNum" label="修改阅卷区域" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row, 1)">
              修改
            </el-button>
            <!-- <el-button type="text" @click="addArea(scope.row, 2)">
              反面
            </el-button> -->
          </template>
        </el-table-column>
        <el-table-column
          prop="questionNum"
          label="操作"
          width="220"
          align="center"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="delQuestion(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="tips">
        <div>注意：</div>
        <p>1、设置绑定批阅时，请停止阅卷;</p>
        <p>
          2、添加绑定时被绑定的小题，阅卷教师设置（包括批阅教师和任务量）必须完全一致;
        </p>
        <!-- <p>
          3、添加绑定时被绑定的小题，不能有教师已阅，可到重评管理处，清空已阅小题后再进行绑定;
        </p> -->
        <p>
          3、添加绑定时被绑定的小题，必须是同一类型，比如普通小题不能与选做题绑定;
        </p>
        <p>4、添加绑定时被绑定的小题，不能包含未处理的异常报警和标记类型;</p>
        <!-- <p>
          6、绑定小题如果已经阅卷，则不能删除该绑定，如需删除需到重评管理处，清空已阅小题;
        </p> -->
      </div>
    </div>
    <el-dialog title="添加绑定" :visible.sync="dialogVisible" width="694px">
      <el-checkbox-group v-model="checkQuestionList" class="check-list-box">
        <template v-for="(v, i) in exammarkList">
          <el-checkbox
            v-if="showQuestion(v)"
            :key="i"
            :label="v.questionNum"
          ></el-checkbox>
        </template>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="btnLoading"
          @click="submitBindQuestion"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  exammarkTeacherList,
  // exammarkRegion,
  // exammarkSaveTeacher,
  // exammarkExportOutline,
  // exammarkClearTeacher,
  // exammarkCopyTeacher,
  // exammarkMarkType,
  // exammarkCalculateWorkload,
} from "@/core/api/exam/examCorrectTask";
import { postExammarkDetail } from "@/core/api/exam/paperMonitor";
import {
  exammarkBindQuestionList,
  passBindquestion,
  exammarkBindquestionDel,
} from "@/core/api/exam/examBindQuestion";
import { getObj } from "@/core/api/exam/exam";
import { setStore, removeStore } from "@/core/util/store";
import ExamDetailsHeader from "@/components/detailsHeader";
export default {
  name: "ExamBindQuestion",
  components: {
    ExamDetailsHeader,
  },
  data() {
    return {
      queryData: {},
      exammarkQuestionList: [],
      exammarkList: [],
      dialogVisible: false,
      btnLoading: false,
      checkQuestionList: [],
      name: "",
      subjectName: "",
      examInformation: {},
    };
  },
  created() {
    this.queryData = this.$route.query;
    this.exammarkTeacherList();
    this.getObj();
    this.exammarkBindQuestionList();
    removeStore({
      name: "bind_awswer_Area",
      type: 1,
    });
    removeStore({
      name: "bind_awswer_Area_id",
      type: 1,
    });
  },
  methods: {
    //获取考试基本信息
    getObj() {
      getObj(this.$route.query.examId).then((res) => {
        this.examInformation = res.data.data;
        this.name = res.data.data.name;
        res.data.data.paperList.map((item) => {
          if (item.paperId == this.$route.query.paperId) {
            this.subjectName = item.subjectName;
          }
        });
      });
    },
    postExammarkDetail(data) {
      postExammarkDetail(data)
        .then(() => {
          this.exammarkBindQuestionList();
          this.$message({
            type: "success",
            message: "删除成功！",
          });
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },

    // 删除阅卷区域
    delArea(item, i) {
      // console.log(item);
      let data = {
        examPaperId: item.examPaperId,
        bindQuestionNum: item.bindQuestionNum,
        id: item.id,
      };
      let array = JSON.parse(JSON.stringify(item.answerArea));
      array.splice(i, 1);
      let str = JSON.stringify(array);
      str = str.substring(0, str.length - 1);
      str = str.substring(1);
      data.answerArea = str;
      this.$confirm("删除后不可恢复，是否继续删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.postExammarkDetail(data);
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    addArea(item, priority) {
      let data = {
        id: item.id,
        examPaperId: item.examPaperId,
        priority: priority,
        indexArea: item.answerArea.length,
      };
      // console.log(item);
      data.type = "add";
      data.fromPages = "bind";
      item.urls.split("，").forEach((item, index) => {
        if (priority == index + 1) {
          data.urls = item;
        }
      });
      this.$router.push({
        name: "ExamAdjustArea",
        query: data,
      });
      // window.open(routeData.href, "_blank");
    },
    editArea(item, indexArea, priority) {
      // item当前项内容 ， indexArea修改的的是第几位  priority 修改图片是第几页
      let data = {
        examPaperId: item.examPaperId,
        id: item.id,
        indexArea: indexArea,
        priority: priority,
      };
      data.type = "edit";
      data.fromPages = "bind";
      item.urls.split("，").forEach((item, index) => {
        if (priority == index + 1) {
          data.urls = item;
        }
      });
      this.$router.push({
        name: "ExamAdjustArea",
        query: data,
      });
      // const routeData = this.$router.resolve({
      //   name: "ExamAdjustArea",
      //   query: data,
      // });
      // window.open(routeData.href, "_blank");
    },
    exammarkRegion(item) {
      // this.$message({
      //   type: "warning",
      //   message: "功能开发中！",
      // });
      let data = {
        bindQuestionNum: item.bindQuestionNum,
        examPaperId: this.queryData.paperId,
      };
      const routeData = this.$router.resolve({
        name: "ExamBindImg",
        query: data,
      });
      window.open(routeData.href, "_blank");
      // exammarkRegion(data).then(() => {
      //   // console.log(res);
      // });
    },
    addBind() {
      this.checkQuestionList = [];
      this.dialogVisible = true;
    },
    showQuestion(v) {
      // console.log(this.exammarkQuestionList);
      // console.log(v);
      let arr = [];
      this.exammarkQuestionList.forEach((item) => {
        item.bindQuestionNum.split(",").forEach((index) => {
          arr.push(index);
        });
      });
      let type = true;
      if (arr.indexOf(v.questionNum) != -1) {
        type = false;
      }
      // 选做题不显示
      if (v.isOption == 1) {
        type = false;
      }
      // 分项给分不显示
      if (v.markType == 3) {
        type = false;
      }
      return type;
      // console.log(arr);
    },
    showQuestionImg(item) {
      // this.$message({
      //   message: "功能开发中",
      //   type: "warning",
      // });
      this.$router.push({
        name: "ExamAdjustArea",
        query: item,
      });
    },
    // 删除绑定批阅
    delQuestion(val) {
      this.$confirm(`此操作将永久删除${val.bindQuestionNum}绑定试题?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            id: val.id,
          };
          exammarkBindquestionDel(data).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.exammarkBindQuestionList();
          });
        })
        .catch(() => {});
    },
    submitBindQuestion() {
      // console.log(this.checkQuestionList);
      if (this.checkQuestionList.length < 2) {
        this.$message({
          message: "请至少选择两个需要绑定的试题",
          type: "warning",
        });
        return;
      }
      let data = {
        // bindQuestionNum: ,
        examPaperId: this.queryData.paperId,
      };
      let array = [];
      let arr = [];
      this.exammarkList.map((index) => {
        if (this.checkQuestionList.indexOf(index.questionNum) != -1) {
          array.push(index.questionNum);
          arr.push(index.sheetDetailId);
        }
      });
      data.bindQuestionNum = array.join(",");
      data.sheetDetailIds = arr.join(",");
      this.btnLoading = true;
      passBindquestion(data)
        .then(() => {
          this.btnLoading = false;
          this.dialogVisible = false;
          this.$router.push({
            name: "unionExamBindSetQuestion",
            query: Object.assign(this.$route.query, data),
          });
          // this.exammarkBindQuestionList();
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    edit(row) {
      let data = {
        bindQuestionNum: row.bindQuestionNum,
        examPaperId: this.queryData.paperId,
        type: "edit",
        sheetDetailIds: row.sheetDetailIds,
      };
      // console.log(row);
      // console.log(data);
      this.answerAreaArr = [];
      this.answerAreaArr.push({
        bindQuestionNum: row.bindQuestionNum,
        answerArea: row.answerArea,
        urls: row.urls.split("，"),
        // id: row.id,
      });
      // });
      this.answerAreaArr = JSON.parse(JSON.stringify(this.answerAreaArr));
      setStore({
        name: "bind_awswer_Area",
        content: JSON.stringify(this.answerAreaArr),
        type: 1,
      });
      setStore({
        name: "bind_awswer_Area_id",
        content: row.id,
        type: 1,
      });
      this.$router.push({
        name: "unionExamBindSetQuestion",
        query: Object.assign(this.$route.query, data),
      });
    },
    // 获取试题列表
    exammarkTeacherList() {
      let data = {
        examPaperId: this.queryData.paperId,
      };
      exammarkTeacherList(data).then((res) => {
        this.exammarkList = res.data.data;
        // console.log(res);
      });
    },
    // 获取绑定列表
    exammarkBindQuestionList() {
      let data = {
        examPaperId: this.queryData.paperId,
      };
      exammarkBindQuestionList(data)
        .then((res) => {
          this.exammarkQuestionList = [];
          // console.log(res.data.data);
          res.data.data.forEach((item) => {
            item.answerArea = JSON.parse("[" + item.answerArea + "]");
            this.exammarkQuestionList.push(item);
          });
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.ExamBindQuestion {
  padding: 24px 0 60px;
  .check-list-box {
    .el-checkbox {
      min-width: 20%;
    }
  }
  .tips {
    padding: 14px 24px 14px 34px;
    margin-top: 24px;
    background: #fdf0ee;
    border: 1px dashed #ff7575;
    color: #777473;
    div {
      line-height: 14px;
      margin-bottom: 12px;
      position: relative;
      &::after {
        content: "!";
        position: absolute;
        letter-spacing: 0;
        width: 13px;
        height: 13px;
        text-align: center;
        line-height: 13px;
        border-radius: 50%;
        background-color: #f17e5e;
        left: -21px;
        font-size: 12px;
        top: 0.5;
        color: #ffffff;
      }
    }
    p {
      margin-bottom: 12px;
    }
  }
  .question-list-box {
    margin-top: 18px;
    background-color: #ffffff;
    padding: 18px;
    .el-table {
      margin-top: 18px;
    }
    .btn-list {
      text-align: left;
    }
  }
}
</style>
